import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import BottomCta from '../../components/bottom-cta'

// Load Page Content
import contentEn from '../../../content/pages/products/emergency-call.en.yaml'
import contentId from '../../../content/pages/products/emergency-call.id.yaml'
import OtherProducts from '../../components/other-products'
import SEO from '../../components/seo'

export const query = graphql`
  query EmergencyCallQuery($language: String!) {
    locales: allLocale(filter: { ns: { in: ["links", "products"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    logo: file(relativePath: { eq: "images/emergency-call/emergency-call-logo.svg" }) {
      id
      publicURL
    }
    mobileCitizenFigure: file(relativePath: { eq: "images/emergency-call/emergency-call-figure-mobile-citizen.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
    mobileResponderFigure: file(
      relativePath: { eq: "images/emergency-call/emergency-call-figure-mobile-responder.png" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
    desktopAdministratorFigure: file(
      relativePath: { eq: "images/emergency-call/emergency-call-figure-desktop-administrator.png" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
    mapFigure: file(relativePath: { eq: "images/emergency-call/emergency-call-figure-map.svg" }) {
      id
      publicURL
    }
  }
`
interface EmergencyCallPagePropsType {
  data: {
    logo: PublicUrlType
    mapFigure: PublicUrlType
    mobileCitizenFigure: IGatsbyImageData
    mobileResponderFigure: IGatsbyImageData
    desktopAdministratorFigure: IGatsbyImageData
  }
}

const EmergencyCallPage = (props: EmergencyCallPagePropsType): JSX.Element => {
  const { t, i18n } = useTranslation()
  const [content, setContent] = useState(contentEn)
  const { logo, mapFigure } = props.data

  const mobileCitizenImg = getImage(props.data.mobileCitizenFigure)
  const mobileResponderImg = getImage(props.data.mobileResponderFigure)
  const desktopAdministratorImg = getImage(props.data.desktopAdministratorFigure)

  useEffect(() => {
    switch (i18n.language) {
      case 'id':
        setContent(contentId)
        break
      case 'en':
        setContent(contentEn)
        break
      default:
        setContent(contentEn)
        break
    }
  }, [i18n.language])

  return (
    <>
      <SEO title={content.title} description={content.description} lang={i18n.language}></SEO>
      {/* Start Hero Section */}
      <section className="uk-light">
        <div
          className="uk-section uk-background-norepeat uk-background-cover tm-emergency-call-hero-background uk-flex uk-flex-middle"
          uk-height-viewport=""
          uk-img=""
          style={{ minHeight: 'calc(100vh)' }}
        >
          <div className="uk-width-1-1">
            <div className="uk-container">
              <div className="uk-flex uk-flex-between" uk-grid="">
                <div className="uk-width-1-2@m uk-flex uk-flex-middle">
                  <div className="uk-animation-slide-top-medium uk-text-center uk-text-left@m">
                    <img className="uk-hidden@m" width="125" alt="" uk-img={logo.publicURL} />
                    <h1 className="uk-heading-small">{content.title}</h1>
                    <p className="uk-visible@m uk-text-lead">{content.description}</p>
                  </div>
                </div>
                <div className="uk-width-1-2@m uk-flex uk-flex-right uk-visible@m">
                  <img width="" height="" alt="" uk-img={logo.publicURL} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Hero Section */}

      <section className="uk-section">
        <div className="uk-with-1-1">
          <div className="uk-container">
            <div className="uk-width-xlarge uk-text-center uk-margin-auto">
              <h2>{content.emergency_services_application.title}</h2>
              <p>{content.emergency_services_application.description}</p>
            </div>
            <section className="uk-section">
              <div uk-grid="">
                <div className="uk-width-1-2@m">
                  <div className="uk-flex uk-flex-center">
                    {mobileCitizenImg && (
                      <GatsbyImage
                        className="uk-width-1-1 uk-first-column"
                        style={{ maxWidth: 220 }}
                        alt="Mobile Citizen Apps"
                        image={mobileCitizenImg}
                      />
                    )}
                  </div>
                </div>
                <div className="uk-width-1-2@m uk-flex uk-flex-middle">
                  <div>
                    <h3>{content.emergency_services_application.mobile_citizens_app.title}</h3>
                    <p>{content.emergency_services_application.mobile_citizens_app.description}</p>
                    <ul className="uk-list uk-list-disc">
                      {content.emergency_services_application.mobile_citizens_app.lists.map(
                        (list: string, index: number) => (
                          <li key={index}>{list}</li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </section>

            <section className="uk-section">
              <div uk-grid="">
                <div className="uk-width-1-2@m uk-flex uk-flex-middle">
                  <div>
                    <h3>{content.emergency_services_application.mobile_responder_app.title}</h3>
                    <p>{content.emergency_services_application.mobile_responder_app.description}</p>
                    <ul className="uk-list uk-list-disc">
                      {content.emergency_services_application.mobile_responder_app.lists.map(
                        (list: string, index: number) => (
                          <li key={index}>{list}</li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
                <div className="uk-width-1-2@m">
                  <div className="uk-flex uk-flex-center">
                    {mobileResponderImg && (
                      <GatsbyImage
                        className="uk-width-1-1 uk-first-column"
                        style={{ maxWidth: 220 }}
                        alt="Mobile Responder App"
                        image={mobileResponderImg}
                      />
                    )}
                  </div>
                </div>
              </div>
            </section>

            <section className="uk-section">
              <div uk-grid="">
                <div className="uk-width-1-2@m">
                  <div className="uk-flex uk-flex-center">
                    {desktopAdministratorImg && (
                      <GatsbyImage
                        className="uk-width-1-1 uk-first-column"
                        style={{ maxWidth: 470 }}
                        image={desktopAdministratorImg}
                        alt="Web Application and Monitoring"
                      />
                    )}
                  </div>
                </div>
                <div className="uk-width-1-2@m uk-flex uk-flex-middle">
                  <div>
                    <h3>{content.emergency_services_application.administrator_panel.title}</h3>
                    <p>{content.emergency_services_application.administrator_panel.description}</p>

                    <ul className="uk-list uk-list-disc">
                      {content.emergency_services_application.administrator_panel.lists.map(
                        (list: string, index: number) => (
                          <li>{list}</li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </section>
            <section className="uk-section">
              <div className="uk-width-xlarge uk-margin-auto uk-text-center"></div>
              <div uk-grid="">
                <div className="uk-width-1-2@m uk-flex uk-flex-middle">
                  <div>
                    <h3>{content.who_is_using.title}</h3>
                    <p>{content.who_is_using.description}</p>
                    <div uk-grid="">
                      <div>
                        <ul className="uk-list uk-list-disc">
                          <li>Badung</li>
                          <li>Balikpapan</li>
                          <li>Bandung</li>
                          <li>Banyuasin</li>
                          <li>Batam</li>
                          <li>Bima</li>
                          <li>Bogor</li>
                          <li>Bogor Kab.</li>
                          <li>Cilegon</li>
                          <li>Cirebon</li>
                        </ul>
                      </div>
                      <div>
                        <ul className="uk-list uk-list-disc">
                          <li>Depok</li>
                          <li>Grobogan</li>
                          <li>Kudus</li>
                          <li>Lampung Barat</li>
                          <li>Lebak</li>
                          <li>Majalengka</li>
                          <li>Manado</li>
                          <li>Musi</li>
                          <li>Banyuasin</li>
                          <li>Pakpak Bharat</li>
                        </ul>
                      </div>
                      <div>
                        <ul className="uk-list uk-list-disc">
                          <li>Palembang</li>
                          <li>Pandeglang</li>
                          <li>Pekanbaru</li>
                          <li>Probolinggo</li>
                          <li>Samarinda</li>
                          <li>Semarang</li>
                          <li>Serang</li>
                          {/* <li>Sibolga</li> */}
                          <li>Solo</li>
                          <li>Tangerang</li>
                          <li>And more...</li>
                        </ul>
                      </div>
                      {/* <div>
                          <ul className="uk-list uk-list-disc">
                            <li>Tebing Tinggi</li>
                            <li>Tomohon</li>
                          </ul>
                        </div> */}
                    </div>
                  </div>
                </div>
                <div className="uk-width-1-2@m">
                  <div className="uk-flex uk-flex-center">
                    <img src={mapFigure.publicURL} alt="map" />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>

      <BottomCta color="#ec2625" />
      <OtherProducts products={content.other_product} />
    </>
  )
}

export default EmergencyCallPage
